import axios, { AxiosInstance } from "axios";
import { Config } from "../types";

class TenantsAPI {
  httpClient: AxiosInstance;
  config: Config;

  constructor(config: Config) {
    this.config = config;
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async getTenantDetails() {
    const response: any = await this.httpClient.get(
      `/tenants/${this.config.tenantId}`
    );
    return response.data.tenant;
  }

  async updateTenantDetails(tenant: any) {
    const response: any = await this.httpClient.put(
      `/tenants/${this.config.tenantId}`,
      tenant
    );
    return response.data.tenant;
  }
}

export default TenantsAPI;
