import axios, { AxiosInstance } from "axios";
import { Technician, TechnicianFilters, Config } from "../types";
import moment from "moment";
import capitalize from "lodash/capitalize";

const formatErrors = (errors: any) =>
  Object.keys(errors).map((field) =>
    errors[field].map((error: string) => `${capitalize(field)} ${error}`)
  );

class TechniciansAPI {
  httpClient: AxiosInstance;
  config: Config;

  constructor(config: Config) {
    this.config = config;
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async loadTechnicians() {
    const response: any = await this.httpClient.get("technicians");
    return response.data.technicians;
  }

  async addNewTechnician(technician: Technician) {
    try {
      const response: any = await this.httpClient.post(
        "technicians",
        technician
      );
      return response.data.technician;
    } catch (e) {
      return Promise.reject({
        errorMessages: e.response.data.result.error
          ? formatErrors(e.response.data.result.error)
          : [],
      });
    }
  }

  async updateTechnician(id: number, technician: Technician) {
    const response = await fetch(this.config.apiURL + "/technicians/" + id, {
      method: "put",
      body: JSON.stringify(technician),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": this.config.userAuthToken,
      },
    });

    let result = await response.json();
    if (response.status !== 200) throw Error(result.message);

    return result.technician;
  }

  async deleteTechnician(id: number) {
    const response = await fetch(this.config.apiURL + "/technicians/" + id, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": this.config.userAuthToken,
      },
    });

    let result = await response.json();
    if (response.status !== 200) throw Error(result.message);

    return result.technician;
  }

  utcTime(time: string) {
    const [h, m] = time.split(":");
    return moment()
      .set({ hour: parseInt(h), minute: parseInt(m), second: 0 })
      .utc()
      .format("HH:mm");
  }
}

export default TechniciansAPI;
