import React, { useContext, useState } from "react";
import { Shift, NewShift } from "../../types";
import ShiftForm from "./ShiftForm";
import Modal from "react-bootstrap/Modal";
import { TechnicianContext } from "../Scheduler";
import moment from "moment";
import { toast } from "react-toastify";

function ShiftModal({
  api,
  shift,
  cancel,
  shiftUpdated,
}: {
  api: any;
  shift: Shift | NewShift;
  cancel: () => void;
  shiftUpdated: (shifts: Shift[]) => void;
}) {
  const currentTechnician = useContext(TechnicianContext);
  const [creating, setCreating] = useState(false);

  const saveShift = async (newShifts: NewShift[]) => {
    try {
      setCreating(true);
      shiftUpdated(
        await Promise.all(newShifts.map((shift) => api.createShift(shift)))
      );
    } catch (e) {
      setCreating(false);
      toast.success("Error creating shift. Please try again.");
    }
  };

  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>Add shifts for technician</Modal.Header>
      <ShiftForm
        newShift={true}
        shift={shift}
        cancel={cancel}
        saveShift={saveShift}
        deleteShift={() => {}}
        deleting={false}
        submitting={creating}
      />
    </Modal>
  );
}

export default ShiftModal;
