import React, { useState } from "react";
import { toast } from "react-toastify";
import { Technician, Service } from "../../types";
import TechnicianForm from "./TechnicianForm";
import Modal from "react-bootstrap/Modal";

function NewTechnicianModal({
  api,
  cancel,
  services,
  technicianUpdated,
}: {
  api: any;
  services: Service[];
  cancel: () => void;
  technicianUpdated: (technician: Technician) => void;
}) {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [creating, setCreating] = useState(false);

  const saveTechnician = async (technicianParams: Technician) => {
    try {
      setCreating(true);
      technicianUpdated(await api.addNewTechnician(technicianParams));
      toast.success("Technician was successfully created.");
    } catch (errors) {
      setCreating(false);
      if (errors.errorMessages) setErrorMessages(errors.errorMessages);
      toast.error("Error creating technician. Please try again.");
    }
  };

  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>Add a new technician</Modal.Header>
      <TechnicianForm
        newTechnician={true}
        cancel={cancel}
        services={services}
        saveTechnician={saveTechnician}
        technician={null}
        errorMessages={errorMessages}
        submitting={creating}
      />
    </Modal>
  );
}

export default NewTechnicianModal;
