import React, { useState } from "react";
import Calendar from "./Calendar";
import NewShiftModal from "./shifts/NewShiftModal";
import EditShiftModal from "./shifts/EditShiftModal";
import NewTechnicianModal from "./technicians/NewTechnicianModal";
import EditTechnicianModal from "./technicians/EditTechnicianModal";
import ActivityLogsModal from "./ActivityLogsModal";
import Header from "./Header";
import {
  Shift,
  NewShift,
  Technician,
  Tenant,
  TechnicianFilters,
  DateNote,
  Service,
  Config,
  AuditLog,
} from "../types";
import ShiftsAPI from "../api/shiftsAPI";
import TechniciansAPI from "../api/techniciansAPI";
import TenantsAPI from "../api/tenantsAPI";
import DateNoteAPI from "../api/dateNoteAPI";
import AuditLogsAPI from "../api/auditLogsAPI";
import FeatureToggleApi from "../api/featureToggleAPI";
import { useEffect } from "react";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { isToday } from "date-fns";
import Spinner from "react-bootstrap/Spinner";
import ServicesAPI from "../api/servicesAPI";
import "../App.scss";
import CopyWeekModal from "./shifts/CopyWeekModal";
import UploadShiftsModal from "./shifts/UploadShiftsModal";
import NewNotesModal from "./notes/NewNotesModal";
import filterTechs from "./filterTechs";
import TenantNotes from "./TenantNotes";

export const TechnicianContext = React.createContext<Technician | null>(null);

function Scheduler({ config }: { config: Config }) {
  const [viewingDateRange, setViewingDateRange] = useState<{
    start: Date;
    end: Date;
  }>();
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [technicians, setTechnicians] = useState<Technician[]>([]);
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [editingShift, setEditingShift] = useState<Shift | null>(null);
  const [newShift, setNewShift] = useState<Shift | NewShift | null>(null);
  const [newTechnicianModal, showNewTechnicianModal] = useState<boolean>(false);
  const [editTechnicianModal, showEditTechnicianModal] =
    useState<boolean>(false);
  const [currentTechnician, setCurrentTechnician] = useState<Technician | null>(
    null
  );
  const [filters, setFilters] = useState<TechnicianFilters | null>(null);
  const [services, setServices] = useState<Service[]>([]);
  const [showCopyWeek, setShowCopyWeek] = useState(false);
  const [showUploadShiftsModal, setShowUploadShiftsModal] = useState(false);
  const [showTechNoteModal, setShowTechNoteModal] = useState<boolean>(false);
  const [showDayNoteModal, setShowDayNoteModal] = useState<boolean>(false);
  const [showTenantNoteModal, setShowTenantNoteModal] =
    useState<boolean>(false);
  const [showActivityLogsModal, setShowActivityLogsModal] =
    useState<boolean>(false);
  const [currentDateNote, setCurrentDateNote] = useState<any | null>(null);
  const [dateNotes, setDateNotes] = useState<DateNote[]>([]);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);

  const shiftsAPI = new ShiftsAPI(config);
  const techniciansAPI = new TechniciansAPI(config);
  const tenantsAPI = new TenantsAPI(config);
  const servicesAPI = new ServicesAPI(config);
  const dateNoteAPI = new DateNoteAPI(config);
  const auditLogsAPI = new AuditLogsAPI(config);
  const featureToggleApi = new FeatureToggleApi(config);
  const [showAuditLogsFeature, setShowAuditLogsFeature] =
    useState<boolean>(false);
  const [showNotesFeature, setShowNotesFeature] = useState<boolean>(false);
  const [isLoadingShifts, setIsLoadingShifts] = useState<boolean>(true);
  const [isLoadingTechnicians, setIsLoadingTechnicians] =
    useState<boolean>(true);

  const loadTechnicians = async () => {
    try {
      const technicians = await techniciansAPI.loadTechnicians();
      setTechnicians(technicians);
      setIsLoadingTechnicians(false);
    } catch (err) {
      setIsLoadingTechnicians(false);
    }
  };

  const getTenantDetails = async () =>
    setTenant(await tenantsAPI.getTenantDetails());

  const loadServices = async () =>
    setServices(await servicesAPI.loadServices());

  const loadDateNotes = async () => {
    if (viewingDateRange !== undefined)
      setDateNotes(
        await dateNoteAPI.getDateNote(
          viewingDateRange.start,
          viewingDateRange.end
        )
      );
  };

  const loadShifts = () => {
    setIsLoadingShifts(true);
    if (viewingDateRange !== undefined)
      shiftsAPI
        .loadShifts(viewingDateRange.start, viewingDateRange.end)
        .then((shifts) => {
          setShifts(shifts);
          setIsLoadingShifts(false);
        })
        .catch((error: any) => {
          setIsLoadingShifts(false);
        });
    else setIsLoadingShifts(false);
  };

  const loadAuditLogs = async (date: Date | string, id: string) =>
    await auditLogsAPI.getTechnicianAuditLogs(date, id);

  const getCurrentDateNote = async (date: string) => {
    try {
      const result = dateNotes.filter((note: DateNote) =>
        moment(date).isSame(note.date, "day")
      );
      console.log("result", result);
      if (result.length) {
        setCurrentDateNote(result[0]);
      } else setCurrentDateNote({ tenantId: 1, date });
    } catch (error) {
      console.log("error", error);
      setCurrentDateNote({ tenantId: 1, date });
    }
  };

  const technicianDeleted = (technician: Technician) => {
    setTechnicians(technicians.filter((tech) => tech.id != technician.id));
  };

  const checkAuditLogsFeatureToggle = () => {
    const auditLogsFeatureName = "ts_audit_logs";
    if (tenant?.sourceSystemId) {
      featureToggleApi
        .featureToggle(auditLogsFeatureName, tenant.sourceSystemId)
        .then((result: any) => {
          if (result && result.data)
            setShowAuditLogsFeature(result.data.feature_enabled);
        });
      const notesFeatureName = "ts_notes";
      featureToggleApi
        .featureToggle(notesFeatureName, tenant.sourceSystemId)
        .then((result: any) => {
          if (result && result.data)
            setShowNotesFeature(result.data.feature_enabled);
        });
    }
  };

  useEffect(() => {
    loadShifts();
    loadDateNotes();
  }, [viewingDateRange]);

  useEffect(() => {
    loadServices();
    loadTechnicians();
    getTenantDetails();
  }, []);

  const updateShifts = (updatedShift: Shift) => {
    const updatedShifts = shifts.map((shift) =>
      shift.id === updatedShift.id ? updatedShift : shift
    );
    setShifts(updatedShifts);
    setEditingShift(null);
  };

  const deleteShift = (deletedShift: Shift) => {
    setEditingShift(null);
    setShifts(shifts.filter((shift) => shift.id !== deletedShift.id));
  };

  const updateTechnician = (updatedTechnician: Technician) => {
    const updatedTechnicians = technicians.map((technician) =>
      technician.id === updatedTechnician.id ? updatedTechnician : technician
    );
    setTechnicians(updatedTechnicians);
    setCurrentTechnician(null);
    showEditTechnicianModal(false);
  };

  const handleShiftDrop = async (
    shift: Shift,
    newStart: Date | null,
    newEnd: Date | null,
    technician: Technician
  ) => {
    const updatedShift = await shiftsAPI.updateShift(shift.id, {
      ...shift,
      technicianId: technician.id,
      startTime: moment(newStart).valueOf(),
      endTime: moment(newEnd).valueOf(),
    });
    updateShifts(updatedShift[0]);
  };

  const updateTechnicianNotes = async (technician: Technician) => {
    const updatedTechnician = await techniciansAPI.updateTechnician(
      technician.id,
      technician
    );
    const updatedTechnicians = technicians.map((technician) =>
      technician.id === updatedTechnician.id ? updatedTechnician : technician
    );
    setTechnicians(updatedTechnicians);
    setCurrentTechnician(null);
    setShowTechNoteModal(false);
  };

  const updateDateNotes = async (dateNote: DateNote) => {
    setCurrentDateNote(null);
    setShowDayNoteModal(false);
    loadDateNotes();
  };

  const updateTenantNotes = async (tenant: Tenant) => {
    setTenant(tenant);
    setShowTenantNoteModal(false);
  };

  return (
    <React.Fragment>
      <div className="r24wrap r24tech-mods" style={{ position: "relative" }}>
        {/* {use showNotesFeature to toggle notes*/}
        <TenantNotes
          tenantInfo={tenant}
          setShowTenantNoteModal={setShowTenantNoteModal}
          readOnly={config.readOnly}
        />

        <Header
          applyFilters={(filters: any) => setFilters(filters)}
          resetFilters={() => setFilters(null)}
          services={services}
          timeZone={config.timeZone}
          config={config}
          disableHeader={isLoadingTechnicians || isLoadingShifts}
        />
        {(isLoadingShifts || isLoadingTechnicians) && (
          <div className="shifts_loader">
            <Spinner animation="border" />
          </div>
        )}
        <Calendar
          viewUpdated={async (start, end) => {
            setViewingDateRange({ start, end });
          }}
          shifts={shifts}
          technicians={filterTechs(technicians, shifts, filters)}
          handleShiftDrop={handleShiftDrop}
          editShift={(shift, technician) => {
            setEditingShift(shift);
            setCurrentTechnician(technician);
          }}
          newShift={(technician, startTime, endTime, allDay) => {
            setCurrentTechnician(technician);
            setNewShift({
              technicianId: technician.id,
              notes: "",
              ...(allDay && !isToday(startTime)
                ? {
                    startTime: moment(startTime)
                      .set({ hour: 9, minute: 0, second: 0 })
                      .valueOf(),
                    endTime: moment(endTime)
                      .subtract(1, "seconds")
                      .set({ hour: 17, minute: 0, second: 0 })
                      .valueOf(),
                  }
                : {
                    startTime: startTime.valueOf(),
                    endTime: endTime.valueOf(),
                  }),
            });
          }}
          showNewTechnicianModal={() => showNewTechnicianModal(true)}
          showEditTechnicianModal={(technician: Technician) => {
            showEditTechnicianModal(true);
            setCurrentTechnician(technician);
          }}
          showTechNoteModal={(orgs: any) => {
            setShowTechNoteModal(true);
            setCurrentTechnician(orgs);
          }}
          showDayNoteModal={async (orgs: any) => {
            setShowDayNoteModal(true);
            await getCurrentDateNote(orgs.date);
          }}
          showActivityLogsModal={(orgs: any) => {
            setShowActivityLogsModal(true);
            setCurrentTechnician(orgs);
          }}
          copyPreviousWeek={() => setShowCopyWeek(true)}
          uploadShifts={() => setShowUploadShiftsModal(true)}
          appConfig={config}
          dateNotes={dateNotes}
          showAuditLogsFeature={showAuditLogsFeature}
          showNotesFeature={showNotesFeature}
          disableHeader={isLoadingTechnicians}
          disableCustomButtons={isLoadingShifts}
        />

        {newTechnicianModal && (
          <NewTechnicianModal
            api={techniciansAPI}
            services={services}
            technicianUpdated={(updatedTechnician: any) => {
              setTechnicians([...technicians, updatedTechnician]);
              showNewTechnicianModal(false);
            }}
            cancel={() => showNewTechnicianModal(false)}
          />
        )}
        {editTechnicianModal && currentTechnician && (
          <EditTechnicianModal
            api={techniciansAPI}
            services={services}
            technician={currentTechnician}
            updateTechnician={updateTechnician}
            cancel={() => showEditTechnicianModal(false)}
            technicianDeleted={technicianDeleted}
          />
        )}
        <TechnicianContext.Provider value={currentTechnician}>
          {newShift && (
            <NewShiftModal
              shift={newShift}
              api={shiftsAPI}
              shiftUpdated={(updatedShifts) => {
                setShifts(shifts.concat(updatedShifts));
                setNewShift(null);
              }}
              cancel={() => setNewShift(null)}
            />
          )}
          {editingShift && (
            <EditShiftModal
              shift={editingShift}
              api={shiftsAPI}
              shiftUpdated={(updatedShift: Shift) => updateShifts(updatedShift)}
              shiftDeleted={(deletedShift: Shift) => deleteShift(deletedShift)}
              cancel={() => setEditingShift(null)}
            />
          )}
          {showCopyWeek && viewingDateRange && (
            <CopyWeekModal
              viewingDateRange={viewingDateRange}
              closeModal={() => setShowCopyWeek(false)}
              shiftsCopied={() => {
                loadShifts();
                setShowCopyWeek(false);
              }}
              api={shiftsAPI}
              shopTZ={config.timeZone}
            />
          )}
          {showUploadShiftsModal && (
            <UploadShiftsModal
              closeModal={() => setShowUploadShiftsModal(false)}
              api={shiftsAPI}
              timeZone={config.timeZone}
              shiftsUploaded={() => {
                setShowUploadShiftsModal(false);
                loadShifts();
              }}
            />
          )}
          {showTechNoteModal && (
            <NewNotesModal
              notesFor="technician"
              cancel={() => setShowTechNoteModal(false)}
              saveNotesApi={async (params: any) =>
                await techniciansAPI.updateTechnician(params.id, {
                  ...params,
                  notes: params?.notes?.trim(),
                })
              }
              data={currentTechnician}
              onSaveNotes={updateTechnicianNotes}
            />
          )}
          {showDayNoteModal && (
            <NewNotesModal
              notesFor="date"
              cancel={() => setShowDayNoteModal(false)}
              saveNotesApi={async (params: any) =>
                await dateNoteAPI.createDateNote({
                  notes: params.notes?.trim(),
                  tenantId: 1,
                  date: moment(currentDateNote?.date).format("YYYY-MM-DD"),
                })
              }
              data={currentDateNote}
              onSaveNotes={updateDateNotes}
            />
          )}
          {showTenantNoteModal && (
            <NewNotesModal
              notesFor="tenant"
              cancel={() => setShowTenantNoteModal(false)}
              saveNotesApi={async (params: any) =>
                await tenantsAPI.updateTenantDetails({
                  ...params,
                  notes: params?.notes?.trim(),
                })
              }
              data={tenant}
              onSaveNotes={updateTenantNotes}
            />
          )}
          {showActivityLogsModal && (
            <ActivityLogsModal
              cancel={() => setShowActivityLogsModal(false)}
              technician={currentTechnician}
              data={auditLogs}
              loadData={loadAuditLogs}
            />
          )}
        </TechnicianContext.Provider>
      </div>
      <ToastContainer
        transition={Slide}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}
export default Scheduler;
