import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function NotesForm({
  cancel,
  saveNote,
  submitting,
  notesTarget,
  showForm,
  notesFor,
}: {
  cancel: () => void;
  saveNote: (shifts: any) => void;
  submitting: boolean;
  notesTarget: any;
  showForm: boolean;
  notesFor: string;
}) {

  const { register, handleSubmit, control } = useForm();
  const [editorState, setEditorState] = useState <any> (null);

  useEffect(() =>{
    if(notesFor == 'tenant' && notesTarget?.notes){
      const html = notesTarget.notes;
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState1 = EditorState.createWithContent(contentState);
      setEditorState(editorState1);
    }
  },[notesTarget]
  )

  const onSubmit = (data: any) => {
    //@ts-ignore
    saveNote({
      ...notesTarget,
      notes: (notesFor == 'tenant') ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : data.notes
    });
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body className="pt-3 pb-3">
        <div className="r24fg mb-0">
          { (notesFor == 'tenant') ? (
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker']
                }}
              />
            ) : showForm ? (
              <textarea
                className="r24fg__input r24fg--textarea w-100"
                rows={3}
                placeholder="Type note"
                defaultValue={notesTarget ? notesTarget.notes : ""}
                name="notes"
                ref={register({
                  minLength: 0,
                })}
              />
            ) : (
              <p className="r24fg__label w-100 font-weight-bold">
                {notesTarget ? notesTarget.notes : ""}
              </p>
            )
          }
        </div>
      </Modal.Body>
      {showForm && (
        <Modal.Footer>
          <button
            type="button"
            className="r24btn r24btn--wide  r24btn--light"
            onClick={cancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={submitting}
            className="r24btn r24btn--wide r24btn--pri"
          >
            {submitting
              ? "Please wait..."
              : "....."
              ? "Save note"
              : "Save changes"}
          </button>
        </Modal.Footer>
      )}
    </form>
  );
}

export default NotesForm;
