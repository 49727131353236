import axios from "axios";

const bootstrapAPI = async (apiURL: string, authToken: string) => {
  try {
    const response: any = await axios.get(`${apiURL}/bootstrap`, {
      headers: {
        "Access-Token": authToken,
      },
    });
    return response.data.tenant;
  } catch (e) {
    return false;
  }
};

export default bootstrapAPI;
