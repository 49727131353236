import React, { useState } from "react";
import { Shift } from "../../types";
import ShiftForm from "./ShiftForm";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { toast } from "react-toastify";

function EditShiftModal({
  api,
  shift,
  cancel,
  shiftUpdated,
  shiftDeleted,
}: {
  api: any;
  shift: Shift;
  cancel: () => void;
  shiftUpdated: (shift: Shift) => void;
  shiftDeleted: (shift: Shift) => void;
}) {
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const saveShift = async (shiftParams: Shift[]) => {
    try {
      setUpdating(true);
      const updatedShiftResponse = await api.updateShift(
        shift.id,
        shiftParams[0]
      );
      shiftUpdated(updatedShiftResponse[0]);
    } catch (e) {
      setUpdating(false);
      toast.success("Error updating shift. Please try again.");
    }
  };

  const deleteShift = async () => {
    try {
      setDeleting(true);
      await api.deleteShift(shift);
      shiftDeleted(shift);
    } catch (e) {
      setDeleting(false);
      toast.success("Error deleting shift. Please try again.");
    }
  };

  return (
    <Modal show={true} onHide={cancel}>
      <Modal.Header closeButton>
        Update shift on {moment(shift.startTime).format("dddd, MMM Do YYYY")}
      </Modal.Header>
      <ShiftForm
        newShift={false}
        canEdit={!shift.readOnly}
        cancel={cancel}
        shift={shift}
        saveShift={saveShift}
        deleteShift={deleteShift}
        deleting={deleting}
        submitting={updating}
      />
    </Modal>
  );
}

export default EditShiftModal;
