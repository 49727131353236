import axios, { AxiosInstance } from "axios";
import moment from "moment";
import { Config } from "../types";

class AuditLogsAPI {
  httpClient: AxiosInstance;
  config: Config;

  constructor(config: Config) {
    this.config = config;
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async getTechnicianAuditLogs(date: Date | string, id: string) {
    const response: any = await this.httpClient.get(
      `/audit_logs?date=${moment(date).format("YYYY-MM-DD")}&technicianId=${id}`
    );
    return response.data.shiftAuditLogs;
  }
}

export default AuditLogsAPI;
