import axios, { AxiosInstance } from "axios";
import { Config } from "../types";
// @ts-ignore
import { saveAs } from 'file-saver';

class ExportScheduleAPI {
  httpClient: AxiosInstance;

  constructor(config: Config) {
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
      responseType: "blob"
    });
  }

  async download(startDate: string, endDate: string) {
    const response: any = await this.httpClient.get("download", {params: {startDate, endDate}});
    saveAs(response.data, "technician_schedules.csv");
    return response.data
  }
}

export default ExportScheduleAPI;
