import React from "react";
import ReactDOM from "react-dom";
import Bootstrap from "./Bootstrap";
import * as serviceWorker from "./serviceWorker";

const queryParams = new URLSearchParams(window.location.search);
const userAuthToken = queryParams.get("userAuthToken");
const readOnly = queryParams.get("readOnly");

ReactDOM.render(
  <div className="App">
    <Bootstrap injectedAuthToken={userAuthToken} readOnly={!!readOnly} />
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
