import { Technician, Shift, TechnicianFilters } from "../types";
import {
  lowerCase,
  includes,
  chain,
  difference,
  map,
  isEmpty,
  some,
  compact,
} from "lodash";
import moment from "moment";

const filterTechs = (
  technicians: Technician[],
  shifts: Shift[],
  filters: TechnicianFilters | null
) => {
  if (filters === null) return technicians;
  return chain(technicians)
    .thru((techs) =>
      isEmpty(filters.technicianName)
        ? techs
        : techs.filter((tech) =>
            includes(
              searchableTechnicianName(tech),
              lowerCase(filters.technicianName)
            )
          )
    )
    .thru((techs) =>
      isEmpty(filters.services)
        ? techs
        : techs.filter(
            (tech) =>
              difference(filters.services, map(tech.expertises, "serviceId"))
                .length === 0
          )
    )
    .thru((techs) =>
      isEmpty(filters.timeRange) || compact(filters.timeRange).length < 2
        ? techs
        : techs.filter((tech) =>
            some(
              shifts.filter((shift) => shift.technicianId == tech.id),
              (shift) =>
                moment(shift.startTime).isSameOrBefore(
                  timeToMoment(shift.startTime, filters.timeRange[1])
                ) &&
                moment(shift.endTime).isAfter(
                  timeToMoment(shift.startTime, filters.timeRange[0])
                )
            )
          )
    )
    .thru((techs) =>
      isEmpty(filters.priority)
        ? techs
        : techs.filter((tech) =>
            some(
              shifts.filter(
                (shift) =>
                  shift.technicianId == tech.id &&
                  filters.priority.includes(shift.priority)
              )
            )
          )
    )
    .value();
};

const searchableTechnicianName = (technician: Technician) =>
  lowerCase(
    `${technician.firstName} ${technician.lastName} ${technician.nickName}`
  );
const timeToMoment = (date: number, time: string) => {
  const [h, m] = time.split(":");
  return moment(date).set({
    hour: parseInt(h),
    minute: parseInt(m),
    second: 0,
  });
};

export default filterTechs;
