import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { XCircle } from "react-bootstrap-icons";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Shift, NewShift } from "../../types";
import { TechnicianContext } from "../Scheduler";
import DateTimeRangePicker from "../DateTimeRangePicker";
import moment, { Moment } from "moment";
import { isPast, isSameMinute, isFuture } from "date-fns";
import map from "lodash/map";
import omit from "lodash/omit";

function ShiftForm({
  shift,
  cancel,
  newShift,
  saveShift,
  deleteShift,
  submitting,
  deleting,
  canEdit = false,
}: {
  shift: Shift | NewShift;
  newShift: boolean;
  cancel: () => void;
  saveShift: (shifts: any) => void;
  deleteShift: () => void;
  submitting: boolean;
  deleting: boolean;
  canEdit?: boolean;
}) {
  const { register, handleSubmit, control, errors } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "timeRanges",
    }
  );

  const addShiftRange = () =>
    append({
      range: { start: moment(shift.startTime), end: moment(shift.endTime) },
    });

  useEffect(() => {
    addShiftRange();
  }, []);

  const onSubmit = (data: any) => {
    saveShift(
      data.timeRanges.map((timeRange: any) => ({
        ...omit(data, "timeRanges"),
        startTime: timeRange.range.start.valueOf(),
        endTime: timeRange.range.end.valueOf(),
        technicianId: shift.technicianId,
      }))
    );
  };
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);

  const currentTechnician = useContext(TechnicianContext);
  const technicianHasSkills =
    currentTechnician && currentTechnician.expertises.length > 0;
  const readOnly = !canEdit && !newShift;

  const isValid = ({ start, end }: { start: Date; end: Date }) => {
    // validate in-process shifts
    if (isPast(new Date(shift.startTime)) && !newShift) {
      return isSameMinute(end, new Date()) || isFuture(end);
    }
    // validate new/future shifts
    return (
      (isFuture(start) || isSameMinute(start, new Date())) && isFuture(end)
    );
  };

  return (
    currentTechnician &&
    shift && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="r24fg">
            <label className="r24fg__label r24fg--top">Assigned to</label>
            <div className="r24fg__input r24fg--textarea r24fg--disabled">
              <span
                className="r24tech__color"
                style={{ backgroundColor: "#2b37c9" }}
              />

              <span className="r24shift__name">
                {currentTechnician.firstName} {currentTechnician.lastName}
              </span>
              <div className="r24shift__type">
                {technicianHasSkills
                  ? map(currentTechnician.expertises, "serviceName").join(", ")
                  : "No designated skills"}
              </div>
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">Shift priority</label>
            <div className="r24modal__right">
              <input
                disabled={readOnly}
                name="priority"
                className="r24fg__input fw"
                ref={register({
                  max: 15,
                  min: 0,
                  required: true,
                })}
                placeholder="Priority"
                type="number"
                max="15"
                min="0"
                defaultValue={(shift as Shift).priority}
              />
              <p className="errors">
                {errors.priority && "Provide priority between 1 & 15"}
              </p>
            </div>
          </div>

          <div className="r24fg">
            <label className="r24fg__label r24fg--top">Scheduled Hours</label>
            <div className="r24modal__right r24fg__timerange">
              {fields.map((item, index) => (
                <div
                  className={`timerange-picker ${
                    errors.timeRanges && "has-errors"
                  }`}
                  key={item.id}
                >
                  {readOnly ? (
                    <Controller
                      control={control}
                      name={`timeRanges[${index}].range`}
                      defaultValue={item.range}
                      rules={{
                        validate: (range: { start: Moment; end: Moment }) =>
                          range.start.isSameOrBefore(range.end),
                      }}
                      render={({ onChange, onBlur, value }) => (
                        <input
                          name={`timeRanges[${index}].range.display`}
                          className="r24fg__input fw"
                          style={{ cursor: "pointer" }}
                          disabled={readOnly}
                          value={`${value.start.format(
                            "M/D/YY h:mm A"
                          )} - ${value.end.format("M/D/YY h:mm A")}`}
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={`timeRanges[${index}].range`}
                      defaultValue={item.range}
                      rules={{
                        validate: (range: { start: Moment; end: Moment }) =>
                          range.start.isSameOrBefore(range.end) &&
                          isValid({
                            start: range.start.toDate(),
                            end: range.end.toDate(),
                          }),
                      }}
                      render={({ onChange, onBlur, value }) => (
                        <>
                          <input
                            onClick={() => setShowDateTimeRangePicker(true)}
                            name={`timeRanges[${index}].range.display`}
                            className="r24fg__input fw"
                            style={{ cursor: "pointer" }}
                            readOnly
                            value={`${value.start.format(
                              "M/D/YY h:mm A"
                            )} - ${value.end.format("M/D/YY h:mm A")}`}
                          />
                          {showDateTimeRangePicker && (
                            <DateTimeRangePicker
                              start={item.range.start.toDate()}
                              end={item.range.end.toDate()}
                              onApply={onChange}
                              setShowDateTimeRangePicker={
                                setShowDateTimeRangePicker
                              }
                              disabled={
                                isPast(new Date(shift.startTime)) && !newShift
                              }
                            />
                          )}
                        </>
                      )}
                    />
                  )}
                  {index !== 0 && (
                    <a
                      href={"#"}
                      className="delete"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <XCircle color="#b20c0c" size={12} />
                    </a>
                  )}
                  {errors.timeRanges && errors.timeRanges.length > 0 && (
                    <p className="errors">
                      {errors.timeRanges[index] &&
                        "Provide a valid time for the shift"}
                    </p>
                  )}
                </div>
              ))}

              {newShift && (
                <a
                  className="r24fg__addlink"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    addShiftRange();
                  }}
                >
                  + Add another range
                </a>
              )}
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label r24fg--top">Shift Notes</label>
            <textarea
              disabled={readOnly}
              className="r24fg__input r24fg--textarea"
              rows={2}
              placeholder="Optional notes about the shift"
              defaultValue={shift.notes}
              name="notes"
              ref={register}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!readOnly && (
            <a
              onClick={() => !deleting && deleteShift()}
              className="mr-auto delete-shift"
            >
              {deleting ? "Deleting..." : "Delete shift"}
            </a>
          )}
          <button
            type="button"
            className="r24btn r24btn--light"
            onClick={cancel}
          >
            Close
          </button>
          {!readOnly && (
            <button
              type="submit"
              disabled={submitting}
              className="r24btn r24btn--wide r24btn--pri"
            >
              {submitting
                ? "Please wait..."
                : newShift
                ? "Add shift"
                : "Save changes"}
            </button>
          )}
        </Modal.Footer>
      </form>
    )
  );
}

export default ShiftForm;
