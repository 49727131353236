import axios, { AxiosInstance } from "axios";
import { Config } from "../types";

class ServicesAPI {
  httpClient: AxiosInstance;

  constructor(config: Config) {
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async loadServices() {
    const response: any = await this.httpClient.get("services");
    return response.data.services;
  }
}

export default ServicesAPI;
