import React, { useState } from "react";
import moment, { Moment } from "moment";
import { endOfDay, isToday, startOfDay } from "date-fns";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateTimeRangePicker({
  setShowDateTimeRangePicker,
  onApply,
  start,
  end,
  disabled,
}: {
  setShowDateTimeRangePicker: (e: boolean) => void;
  onApply: ({ start, end }: { start: Moment; end: Moment }) => void;
  start: Date;
  end: Date;
  disabled: boolean;
}) {
  const [fromDate, setFromDate] = useState(start);
  const [toDate, setToDate] = useState(end);

  return (
    <Modal
      show={true}
      onHide={() => setShowDateTimeRangePicker(false)}
      centered={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Body className="schedule-hours-modal-body">
        <div className="date-pickers-main-container">
          <div className="date-picker-container">
            <span className="date-time-label">From Date</span>
            <DatePicker
              className="date-picker"
              open={!disabled}
              disabled={disabled}
              onChange={(date) => setFromDate(date as Date)}
              selected={fromDate}
              showTimeInput
              shouldCloseOnSelect={false}
              calendarStartDay={1}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="M/d/yy h:mm a"
              minDate={new Date()}
              minTime={isToday(fromDate) ? new Date() : startOfDay(fromDate)}
              maxTime={
                isToday(fromDate) ? endOfDay(fromDate) : endOfDay(fromDate)
              }
            />
          </div>
          <div className="date-picker-container">
            <span className="date-time-label">To Date</span>
            <DatePicker
              className="date-picker"
              open={true}
              onChange={(date) => setToDate(date as Date)}
              selected={toDate}
              showTimeInput
              shouldCloseOnSelect={false}
              calendarStartDay={1}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="M/d/yy h:mm a"
              minDate={new Date()}
              minTime={isToday(toDate) ? new Date() : startOfDay(toDate)}
              maxTime={isToday(toDate) ? endOfDay(toDate) : endOfDay(toDate)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          className="r24btn r24btn--light"
          onClick={() => setShowDateTimeRangePicker(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="r24btn r24btn--wide r24btn--pri"
          onClick={() => {
            onApply({ start: moment(fromDate), end: moment(toDate) });
            setShowDateTimeRangePicker(false);
          }}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DateTimeRangePicker;
