import React, { useEffect, useCallback } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import Select from "react-select";
// @ts-ignore
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { Service, Config } from "../types";
import debounce from "lodash/debounce";
import range from "lodash/range";
import ExportSchedule from "./ExportSchedule";
import { Spinner } from "react-bootstrap";

function Header({
  applyFilters,
  resetFilters,
  services,
  timeZone,
  config,
  disableHeader,
}: {
  applyFilters: (filters: any) => void;
  resetFilters: () => void;
  services: Service[];
  timeZone: string;
  config: Config;
  disableHeader: boolean;
}) {
  const { register, handleSubmit, reset, control, watch } = useForm();
  const onSubmit = (data: any) =>
    applyFilters({
      technicianName: data.technicianName,
      services: (data.services || []).map(
        (service: { label: string; value: number }) => service.value
      ),
      timeRange: data.timeRange,
      priority: (data.priority || []).map(
        (priority: { label: string; value: number }) => priority.value
      ),
    });

  const debouncedSubmit = useCallback(
    debounce(() => handleSubmit(onSubmit)(), 200),
    []
  );

  const watchFields = useWatch({
    control,
  });

  useEffect(() => {
    debouncedSubmit();
  }, [watchFields, handleSubmit]);

  return (
    <div className="r24phead r24phead--trans" style={{ position: "relative" }}>
      {disableHeader && <div className="shifts_header_loader" />}
      <div className="r24 wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="r24filters">
            <div className="r24filters__single r24filters__label r24label">
              Filter by:
            </div>
            <div className="r24fg r24fg--vert r24filters__single r24fg--fixed">
              <input
                type="text"
                className="r24fg__input r24filters__input"
                placeholder="Technician Name"
                name="technicianName"
                ref={register}
              />
            </div>
            <div className="r24fg r24fg--multi r24fg--vert r24filters__single">
              <Controller
                as={Select}
                name="services"
                control={control}
                placeholder="Expertise"
                isMulti
                defaultValue={[]}
                options={services.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
                closeMenuOnSelect={false}
                className="multi-select"
                classNamePrefix="r24"
              />
            </div>

            <div className="r24fg r24fg--multi r24fg--vert r24filters__single">
              <Controller
                as={Select}
                name="priority"
                placeholder="Priority"
                control={control}
                isMulti
                defaultValue={[]}
                options={range(1, 16).map((prio) => ({
                  value: prio,
                  label: prio,
                }))}
                closeMenuOnSelect={false}
                className="multi-select"
                classNamePrefix="r24"
              />
            </div>
            <div className="r24fg r24fg--time r24fg--vert r24filters__single r24fg--fixed">
              <Controller
                control={control}
                name="timeRange"
                defaultValue={[]}
                render={({ onChange, onBlur, value }) => (
                  <TimeRangePicker
                    hourPlaceholder="hh"
                    minutePlaceholder="mm"
                    secondPlaceholder="ss"
                    name="timeRange"
                    disableClock={true}
                    clearIcon={null}
                    onChange={(values: any) => onChange(values)}
                    value={value}
                    disabled={true}
                  />
                )}
              />
            </div>
            <button className="r24btn r24btn--small r24btn--pri r24filters__single">
              Apply
            </button>
            <input
              className="r24btn r24btn--small r24btn--light"
              type="button"
              value="Reset"
              onClick={() => {
                reset({
                  name: null,
                  services: null,
                  timeRange: [],
                  priority: [],
                });
                resetFilters();
              }}
            />

            <ExportSchedule config={config} />
            {/* <button
              className="r24btn r24btn--small r24btn--light r24filters__single r24filters--right"
              data-toggle="modal"
              data-target="#copy-conflict-week"
            >
              <img src={copyIcon} className="mar-r-2" alt="copy" /> Copy
              Previous Week
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Header;
