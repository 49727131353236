import React, { useState } from "react";
import { toast } from "react-toastify";
import { Technician, Service } from "../../types";
import TechnicianForm from "./TechnicianForm";
import Modal from "react-bootstrap/Modal";
import DeleteTechnicianModal from "./DeleteTechnicianModal";

function EditTechnicianModal({
  technician,
  api,
  services,
  cancel,
  updateTechnician,
  technicianDeleted,
}: {
  technician: Technician;
  api: any;
  services: Service[];
  cancel: () => void;
  updateTechnician: (technician: Technician) => void;
  technicianDeleted: (technician: Technician) => void;
}) {
  const [updating, setUpdating] = useState(false);
  const [deletingTechnician, setDeletingTechnician] = useState<boolean>(false);

  const saveTechnician = async (technicianParams: Technician) => {
    try {
      setUpdating(true);
      const updatedTechnician = await api.updateTechnician(
        technician.id,
        technicianParams
      );
      updateTechnician(updatedTechnician);
      toast.success("Technician was successfully updated.");
    } catch (e) {
      setUpdating(false);
      toast.error("Error saving technician. Please try again.");
    }
  };

  const close = () => {
    cancel()
    setDeletingTechnician(false)
  }

  const deleteTechnician = async () => {
    try {
      await api.deleteTechnician(technician.id)
      technicianDeleted(technician)
      close()
      toast.success("Successfully deleted technician.");
    }
    catch (e){
      close()
      toast.error(e.message);
    }
  }

  return (
    deletingTechnician ? (
      <DeleteTechnicianModal
        deleteTechnician={deleteTechnician}
        close={close}
      />
    ) : (
      <Modal show={true} onHide={cancel}>
        <Modal.Header closeButton>Update technician details</Modal.Header>
        <TechnicianForm
          newTechnician={false}
          cancel={cancel}
          services={services}
          saveTechnician={saveTechnician}
          technician={technician}
          errorMessages={[]}
          submitting={updating}
          setDeletingTechnician={setDeletingTechnician}
        />
      </Modal>
    )
  );
}

export default EditTechnicianModal;
