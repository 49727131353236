import React, { useEffect, useState, useMemo } from "react";
import { Technician } from "../types";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Pagination from "./pagination/Pagination";

function ActivityLogsModal({
  technician,
  cancel,
  data,
  loadData,
}: {
  technician: Technician | any;
  cancel: () => void;
  data: any;
  loadData: (date: Date | string, id: string) => void;
}) {
  let PageSize = 5;
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [logs, setLogs]: any = useState([]);

  useEffect(() => {
    onDateChange({ target: { value: date } });
  }, []);

  const onDateChange = async (event: any) => {
    setLoading(true);
    setDate(moment(event.target.value).format("YYYY-MM-DD"));
    if (moment(event.target.value).isValid()){
      const data = await loadData(event.target.value, technician.id);
      setLogs(data);
    }else{
      setLogs([]);
    }
    setLoading(false);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return logs.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, logs]);

  // console.log("currentTableData", currentTableData);

  return (
    <Modal show={true} onHide={cancel} size={"lg"} scrollable={true}>
      <Modal.Header closeButton>Activity Log</Modal.Header>
      <Modal.Body>
        <div className="r24tech__col bg-light p-1 pl-2 pr-2 r24tech__activity__header">
          <span
            className="r24tech__color"
            style={{ backgroundColor: technician.labelColor }}
          />
          <strong className="r24tech__name">
            {technician.nickName ||
              `${technician.lastName}, ${technician.firstName}`}
          </strong>
          {technician.phoneNumber && (
            <>
              <br />
              <span className="r24tech__number">{technician.phoneNumber}</span>
            </>
          )}
        </div>
        <div className="d-flex align-items-center pt-2 mt-3 pb-2">
          <label
            htmlFor="shiftLogDate"
            className="r24tech__number p-0 pr-2 mb-0"
          >
            Since:{" "}
          </label>
          <input
            type="date"
            id="shiftLogDate"
            name="shiftLogDate"
            onChange={onDateChange}
            value={date}
            className="border border-dark rounded"
          />
        </div>
        <table className="table mt-3 mb-0 r24tech__activity__table">
          <thead>
            <tr>
              <th scope="col">Action</th>
              <th scope="col">Time</th>
              <th scope="col">User</th>
              <th scope="col">ShiftId</th>
              <th scope="col">Notes</th>
              <th scope="col">Priority</th>
              <th scope="col">Shift Start Time</th>
              <th scope="col">Shift End Time</th>
            </tr>
          </thead>
          <tbody>
            {!loading && logs.length ? (
              currentTableData.map((log: any) => {
                return (
                  <tr key={log.id}>
                    <td>{log.action}</td>
                    <td>{moment(log.stamp).format("MM/DD/YY hh:mm A")}</td>
                    <td scope="row">{log.admin.name}</td>
                    <td> {log.shiftId}</td>
                    <td> {log.notes}</td>
                    <td> {log.priority}</td>
                    <td> {moment(log.startTime).format("MM/DD/YY hh:mm A")}</td>
                    <td> {moment(log.endTime).format("MM/DD/YY hh:mm A")}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th colSpan={7} className="text-center">
                  {loading ? "loading..." : "No logs."}
                </th>
              </tr>
            )}
          </tbody>
        </table>
        <div className="d-flex align-items-center justify-content-center">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={logs.length}
            pageSize={PageSize}
            onPageChange={(page: any) => setCurrentPage(page)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ActivityLogsModal;
