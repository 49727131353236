import React, { useState } from "react";
import {
  CardText,
  PencilSquare,
  XLg as Close,
  PlusSquare,
} from "react-bootstrap-icons";
import moment from "moment";
import { DateNote } from "../../types";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function NotePopover(props: any) {
  let result = [];
  if (!props.isResourceView) {
    result = props.dateNotes.filter((note: DateNote) =>
      moment(props.args.date).isSame(note.date, "day")
    );
  }
  const notes = props.isResourceView
    ? props?.args?.notes
    : result?.length
    ? result[0].notes
    : "";
  const [show, setShow] = useState(false);

  const popover = (
    <Popover id="popover-basic" style={{ zIndex: 22222 }}>
      <Popover.Title as="h3" className="d-flex justify-content-between">
        <label className="mr-2 mb-0">Notes</label>
        <div>
          {show && !props.readOnly ? (
            notes ? (
              <PencilSquare
                role="button"
                color="#19333F"
                size={15}
                onClick={() => {
                  setShow(false);
                  props.showDayNoteModal(props.args);
                }}
              />
            ) : (
              <PlusSquare
                role="button"
                color="#19333F"
                size={15}
                onClick={() => {
                  setShow(false);
                  props.showDayNoteModal(props.args);
                }}
              />
            )
          ) : (
            <></>
          )}
          <Close
            role="button"
            color="#19333F"
            size={15}
            className="ml-2"
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
      </Popover.Title>
      <Popover.Content>
        {notes ? (
          notes
        ) : (
          <label className="m-1">
            {!props.readOnly ? "Please add notes by clicking + icon" : ""}
          </label>
        )}
      </Popover.Content>
    </Popover>
  );
  return (
    <span className="text-dark">
      <label className="mb-0" style={{ fontSize: 12 }}>
        {props.args.text}
      </label>
      {(props.args?.view?.type === "resourceTimelineWeek" ||
        props.isResourceAreaHeader ||
        props.isResourceView) && (
        // use props.showNotesFeature to toggle notes button.
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 0 }}
            overlay={popover}
            trigger="click"
            show={show}
            onToggle={(args) => {
              if (notes) setShow(args);
              if (!notes && !props.readOnly) setShow(args);
            }}
            rootClose={true}
            rootCloseEvent={"wheel"}
            onExited={() => {
              setShow(false);
            }}
          >
            <CardText
              role="button"
              className="ml-2"
              color={notes ? "#4285F4" : "#7A7777"}
              size={12}
            />
          </OverlayTrigger>
        )}
    </span>
  );
}

export default NotePopover;
