import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import "react-phone-number-input/style.css";
import {
  isValidPhoneNumber,
  formatPhoneNumber,
} from "react-phone-number-input";
//@ts-ignore
import PhoneInput from "react-phone-number-input/input";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Technician, Service, TechnicianExpertise } from "../../types";
import { CirclePicker } from "react-color";
import sample from "lodash/sample";
import omit from "lodash/omit";

function TechnicianForm({
  cancel,
  technician,
  newTechnician,
  services,
  saveTechnician,
  errorMessages,
  submitting,
  setDeletingTechnician
}: {
  cancel: () => void;
  newTechnician: boolean;
  technician: Technician | null;
  services: Service[];
  saveTechnician: (technician: Technician) => void;
  errorMessages: string[];
  submitting: boolean;
  setDeletingTechnician: (show: boolean) => void;
}) {
  const { register, handleSubmit, control, errors } = useForm();
  const [passwordVisible, togglePasswordVisiblity] = useState<boolean>(false);
  const expertiseLevels = ["intermediate", "superior", "advanced"];
  const expertiseGroups: any = expertiseLevels.reduce(
    (group, level) => ({
      ...group,
      [level]: (technician?.expertises || []).reduce(
        (opts, expertise) =>
          opts.concat(
            expertise.expertiseLevel === level
              ? {
                  label: expertise.serviceName,
                  value: expertise.serviceId,
                }
              : []
          ),
        [] as any
      ),
    }),
    {}
  );

  const onSubmit = (data: any) => {
    const expertises = expertiseLevels.reduce(
      (result, level) =>
        result.concat(
          (data[level] || []).map((expertise: any) => ({
            expertiseLevel: level,
            serviceId: expertise.value,
          }))
        ),
      [] as TechnicianExpertise[]
    );
    //@ts-ignore
    saveTechnician({
      ...omit(data, expertiseLevels),
      expertises,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
      personalPhone: formatPhoneNumber(data.personalPhone),
      afterHoursPhone: formatPhoneNumber(data.afterHoursPhone),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <div className="modal-body">
          {errorMessages.length > 0 && (
            <Alert variant={"danger"} className="r24-error-alerts">
              <strong>Please address the following issues:</strong>
              <ul>
                {errorMessages.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="r24fg">
            <label className="r24fg__label">
              Full Name <span className="r24red">*</span>
            </label>
            {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
            <div className="r24modal__right">
              <div className="r24fg__group r24fg--sep">
                <input
                  name="firstName"
                  className={`r24fg__input ${errors.firstName && "has-errors"}`}
                  ref={register({ required: true })}
                  placeholder="First"
                  disabled={!newTechnician}
                  defaultValue={technician ? technician.firstName : ""}
                />
                <input
                  name="lastName"
                  className={`r24fg__input ${errors.lastName && "has-errors"}`}
                  ref={register({ required: true })}
                  placeholder="Last"
                  disabled={!newTechnician}
                  defaultValue={technician ? technician.lastName : ""}
                />
              </div>
              {(errors.firstName || errors.lastName) && (
                <p className="errors">A valid first & last name is required</p>
              )}
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">Nickname</label>
            <div className="r24modal__right">
              <input
                name="nickName"
                className={`r24fg__input fw ${errors.nickName && "has-errors"}`}
                ref={register}
                placeholder="Nickname"
                defaultValue={technician ? technician.nickName : ""}
              />
            </div>
          </div>{" "}
          <div className="r24fg">
            <label className="r24fg__label">
              Email <span className="r24red">*</span>
            </label>
            <div className="r24modal__right">
              <input
                name="email"
                className={`r24fg__input fw ${errors.email && "has-errors"}`}
                type="email"
                autoComplete="off"
                disabled={!newTechnician}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                defaultValue={technician ? technician.email : ""}
                placeholder="Email"
              />
              {errors.email && (
                <p className="errors">Provide a valid email address</p>
              )}
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">
              Work phone <span className="r24red">*</span>
            </label>
            <div className="r24modal__right">
              <Controller
                control={control}
                name="phoneNumber"
                rules={{
                  validate: (value) => !!value && isValidPhoneNumber(value),
                }}
                defaultValue={
                  technician && technician.phoneNumber
                    ? `+1${technician.phoneNumber.replace(/\D/g, "")}`
                    : ""
                }
                render={({ onChange, value }) => (
                  <PhoneInput
                    country="US"
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    value={value}
                    onChange={onChange}
                    className={`r24fg__phone ${
                      errors.phoneNumber && "has-errors"
                    }`}
                  />
                )}
              />
              {errors.phoneNumber && (
                <p className="errors">Provide a valid phone number</p>
              )}
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">
              Personal phone <span className="r24red"></span>
            </label>
            <div className="r24modal__right">
              <Controller
                control={control}
                name="personalPhone"
                defaultValue={
                  technician && technician.personalPhone
                    ? `+1${technician.personalPhone.replace(/\D/g, "")}`
                    : ""
                }
                rules={{
                  validate: (value) => !value || isValidPhoneNumber(value),
                }}
                render={({ onChange, value }) => (
                  <PhoneInput
                    country="US"
                    placeholder="Enter phone number"
                    name="personalPhone"
                    value={value}
                    onChange={onChange}
                    className={`r24fg__phone ${
                      errors.personalPhone && "has-errors"
                    }`}
                  />
                )}
              />
              {errors.personalPhone && (
                <p className="errors">Enter a valid phone number</p>
              )}
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">
              After-hours phone <span className="r24red"></span>
            </label>
            <div className="r24modal__right">
              <Controller
                control={control}
                name="afterHoursPhone"
                defaultValue={
                  technician && technician.afterHoursPhone
                    ? `+1${technician.afterHoursPhone.replace(/\D/g, "")}`
                    : ""
                }
                rules={{
                  validate: (value) => !value || isValidPhoneNumber(value),
                }}
                render={({ onChange, value }) => (
                  <PhoneInput
                    country="US"
                    placeholder="Enter phone number"
                    name="afterHoursPhone"
                    value={value}
                    onChange={onChange}
                    className={`r24fg__phone ${
                      errors.afterHoursPhone && "has-errors"
                    }`}
                  />
                )}
              />
              {errors.afterHoursPhone && (
                <p className="errors">Enter a valid phone number</p>
              )}
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">Reference Number</label>
            <input
              name="referenceNumber"
              className="r24fg__input"
              disabled={!newTechnician}
              ref={register}
              defaultValue={technician ? technician.referenceNumber : ""}
            />
          </div>
          {newTechnician && (
            <div className="r24fg">
              <label className="r24fg__label">
                Password <span className="r24red">*</span>
              </label>
              <div className="r24modal__right r24pw">
                <input
                  name="password"
                  className={`r24fg__input r24pw__input ${
                    errors.password && "has-errors"
                  }`}
                  autoComplete="new-password"
                  type={passwordVisible ? "text" : "password"}
                  ref={register({ required: true, minLength: 8 })}
                  defaultValue={technician ? technician.password : ""}
                />
                <span
                  className="r24pw__show"
                  onClick={() => togglePasswordVisiblity(!passwordVisible)}
                >
                  Show
                </span>
                {errors.password && (
                  <p className="errors">
                    Enter password that's atleast 8 chars long
                  </p>
                )}
              </div>
            </div>
          )}
          <div className="r24fg">
            <label className="r24fg__label">Superior Skill</label>
            <div className="r24modal__right">
              <Controller
                as={Select}
                name="superior"
                control={control}
                isMulti
                defaultValue={expertiseGroups.superior}
                options={services.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
                className="multi-select"
                classNamePrefix="r24"
                closeMenuOnSelect={false}
              />
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">Advanced Skill</label>
            <div className="r24modal__right">
              <Controller
                as={Select}
                name="advanced"
                control={control}
                isMulti
                defaultValue={expertiseGroups.advanced}
                options={services.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
                className="multi-select"
                classNamePrefix="r24"
                closeMenuOnSelect={false}
              />
            </div>
          </div>
          <div className="r24fg">
            <label className="r24fg__label">Intermediate Skill</label>
            <div className="r24modal__right">
              <Controller
                as={Select}
                name="intermediate"
                control={control}
                isMulti
                defaultValue={expertiseGroups.intermediate}
                options={services.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
                className="multi-select"
                classNamePrefix="r24"
                closeMenuOnSelect={false}
              />
            </div>
          </div>
          <div className="r24fg r24modal__colors">
            <label className="r24fg__label">Label Color</label>
            <div className="r24modal__right r24colors">
              <Controller
                control={control}
                name="labelColor"
                defaultValue={
                  newTechnician
                    ? sample(availableColors)
                    : technician?.labelColor
                }
                render={({ onChange, onBlur, value }) => (
                  <CirclePicker
                    onChangeComplete={(color: any) => onChange(color.hex)}
                    color={value}
                    circleSize={15}
                    width={"220px"}
                    circleSpacing={8}
                    colors={availableColors}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {!newTechnician && (
            <button
              className="mr-auto delete-technician"
              type="button"
              onClick={() => {
                setDeletingTechnician(true)
              }}
            >
              Delete Technician
            </button>
          )}

          <button
            className="r24btn r24btn--light"
            type="button"
            onClick={cancel}
          >
            Close
          </button>
          {submitting ? (
            <button disabled className="r24btn r24btn--wide r24btn--pri">
              Please wait...
            </button>
          ) : (
            <button type="submit" className="r24btn r24btn--wide r24btn--pri">
              {newTechnician ? "Add technician" : "Save changes"}
            </button>
          )}
        </div>
      </Modal.Body>
    </form>
  );
}
TechnicianForm.defaultProps = {
  setDeletingTechnician: (show: boolean) => {}
}
export default TechnicianForm;

const availableColors = [
  "#050d70",
  "#4e0a6c",
  "#730a21",
  "#094375",
  "#2a6e0a",
  "#726b0a",
  "#714309",
  "#710a5b",
  "#084869",
  "#2b37c9",
  "#982bc9",
  "#c92b4d",
  "#2b80c9",
  "#55b727",
  "#c9be2b",
  "#c9832b",
  "#c92ba7",
  "#2b93c9",
  "#7f87e4",
  "#c27fe0",
  "#e3768d",
  "#7cb1de",
  "#9adc7c",
  "#ded66e",
  "#e8b97c",
  "#e889d4",
  "#8fcae9",
];
