import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";

const CopyWeekModal = ({
  viewingDateRange,
  closeModal,
  shiftsCopied,
  api,
  shopTZ,
}: {
  viewingDateRange: {
    start: Date;
    end: Date;
  };
  closeModal: () => void;
  shiftsCopied: () => void;
  api: any;
  shopTZ: string;
}) => {
  const [overwrite, setOverwrite] = useState<boolean>(false);
  const [copying, setCopying] = useState(false);

  const copyPreviousWeek = async () => {
    var lastMonday = moment(viewingDateRange.start)
      .subtract(1, "weeks")
      .startOf("isoWeek");
    var lastSunday = moment(viewingDateRange.start)
      .subtract(1, "weeks")
      .endOf("isoWeek");
    var thisMonday = moment(viewingDateRange.start).startOf("isoWeek");

    var request = {
      from: lastMonday.valueOf(),
      to: lastSunday.valueOf(),
      when: thisMonday.valueOf(),
      overwrite: overwrite,
    };

    try {
      setCopying(true);
      await api.copyShift(request);
      shiftsCopied();
      toast.success("Shifts were copied sucessfully from the previous week.");
    } catch (e) {
      setCopying(false);
      toast.success("There was an error copying the shifts. Please try again.");
    }
  };

  return (
    <Modal show={true} onHide={closeModal} animation={false}>
      <Modal.Header closeButton>Copy Previous Week</Modal.Header>
      <Modal.Body>
        {" "}
        <p>How would you like to copy the previous week?</p>
        <div className="r24fg">
          <div className="r24fg__radset">
            <label
              htmlFor="overwrite-conflicts"
              className="r24fg__lab-cont r24fg--fw"
            >
              <input
                type="radio"
                className="r24fg__radio"
                id="overwrite-conflicts"
                name="copy-week"
                defaultChecked={true}
                onChange={(e) => {
                  setOverwrite(false);
                }}
              />
              <span className="r24fg__rbtn" />
              <span className="r24fg__inlabel">Do not overwrite schedule</span>
            </label>
            <label
              htmlFor="avoid-conflicts"
              className="r24fg__lab-cont r24fg--fw"
            >
              <input
                type="radio"
                className="r24fg__radio"
                id="avoid-conflicts"
                name="copy-week"
                onChange={(e) => {
                  setOverwrite(true);
                }}
              />
              <span className="r24fg__rbtn" />
              <span className="r24fg__inlabel">Overwrite schedule</span>
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="r24btn"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={copying}
          className="r24btn r24btn--pri r24btn--wide"
          onClick={() => copyPreviousWeek()}
        >
          {copying ? "Copying..." : "Proceed"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyWeekModal;
