import axios, { AxiosInstance } from "axios";
import { Config } from "../types";
import moment from "moment";
// import capitalize from "lodash/capitalize";

class DateNoteAPI {
  httpClient: AxiosInstance;

  constructor(config: Config) {
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async getDateNote(start: Date, end: Date) {
    const response: any = await this.httpClient.get(`/day_notes?day_notes`, {
      params: {
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      },
    });
    return response.data.dayNote;
  }

  async createDateNote(dayNote: any) {
    const response: any = await this.httpClient.put("day_notes", dayNote);
    return response.data.dayNote;
  }
}
export default DateNoteAPI;
