import React, { useRef, useState, useEffect } from "react";
import { Config } from "./types";
import Scheduler from "./components/Scheduler";
import api from "./api/bootstrapAPI";
import axios from "axios";
const Bootstrap = ({
  injectedAuthToken,
  readOnly,
}: {
  injectedAuthToken: string | null;
  readOnly: boolean;
}) => {
  const authTokenInput = useRef();
  const [authToken, setAuthToken] = useState(injectedAuthToken);
  const [appConfig, setAppConfig] = useState<Config>();
  const [tenantUnavailable, setTenantUnavailable] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL || "";
  const schedulerLicenseKey =
    process.env.REACT_APP_FULLCALENDAR_LICENSE || "dummy-license-key";

  useEffect(() => {
    if (authToken)
      api(apiURL, authToken).then((tenant: any) => {
        if (tenant)
          setAppConfig({
            timeZone: tenant.timeZone,
            userAuthToken: authToken,
            apiURL: apiURL,
            schedulerLicenseKey: schedulerLicenseKey,
            readOnly: readOnly,
            tenantId: tenant.id,
          });
        else setTenantUnavailable(true);
      });
  }, [authToken]);

  if (tenantUnavailable)
    return (
      <p>
        This tenant is not configured to use Tech Scheduler yet. Please reach
        out to help@reach24.net
      </p>
    );

  if (appConfig) return <Scheduler config={appConfig} />;

  if (authToken) return <p>Loading the application...</p>;

  return (
    <div className="Bootstrap">
      Please access this application via Reach or provide an auth token:
      <form
        onSubmit={() => {
          //@ts-ignore
          setAuthToken(authTokenInput.current.value);
        }}
      >
        {/* @ts-ignore */}
        <input type="text" ref={authTokenInput} name="authToken" />
        <input type="submit" />
      </form>
    </div>
  );
};

export default Bootstrap;
