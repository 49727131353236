import React, { useContext, useState, useEffect } from "react";

import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  PencilSquare,
  PlusSquare,
  ArrowUpShort as ArrowUp,
  ArrowDownShort as ArrowDown,
} from "react-bootstrap-icons";
import { Tenant } from "../types";

function AccordionToggle({ children, eventKey, callback }: any) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  if (currentEventKey === null) {
    return (
      <ArrowDown
        role="button"
        color="#19333F"
        fontWeight={"bold"}
        size={16}
        onClick={decoratedOnClick}
      />
    );
  } else {
    return (
      <ArrowUp
        role="button"
        color="#19333F"
        size={16}
        fontWeight={"bold"}
        onClick={decoratedOnClick}
      />
    );
  }
}

function TenantNotes({
  tenantInfo,
  setShowTenantNoteModal,
  readOnly,
}: {
  tenantInfo: Tenant | any;
  setShowTenantNoteModal: any;
  readOnly: boolean;
}) {

  const [showMore, setShowMore] = useState(false);
  const [editorState, setEditorState] = useState <any> (null);

  useEffect(() =>{
    if(tenantInfo?.notes){
      const html = tenantInfo.notes;
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState1 = EditorState.createWithContent(contentState); 
      setEditorState(editorState1);
    }
  },[tenantInfo]
  )

  return (
    <div>
      <div
        style={{
          display: "block",
          width: "100%",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {/* <h4>React-Bootstrap Accordion Component</h4> */}
        <Accordion className="mt-2" defaultActiveKey="0">
          <Card
            style={{
              fontSize: 12,
              backgroundColor: "#E5E5E5",
              borderRadius: 0,
              border: 0,
            }}
          >
            <Card.Header
              className="d-flex align-items-center border-0 rounded-0 pb-2 pt-2"
              style={{ backgroundColor: "#E5E5E5" }}
            >
              <AccordionToggle eventKey="0" />
              <span className="pl-1 mr-2 font-weight-bold">Notes</span>
              {!readOnly ? (
                tenantInfo?.notes ? (
                  <PencilSquare
                    role="button"
                    color="#19333F"
                    size={12}
                    fontWeight={"bold"}
                    onClick={setShowTenantNoteModal}
                  />
                ) : (
                  <PlusSquare
                    role="button"
                    color="#19333F"
                    size={12}
                    fontWeight={"bold"}
                    onClick={setShowTenantNoteModal}
                  />
                )
              ) : (
                <></>
              )}
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body
                className="pt-1 pl-4 pb-2 ml-3 mr-3"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span
                  className={`mb-0 text-justify text-black ${
                    tenantInfo?.notes?.length > 400 &&
                    !showMore &&
                    "tenant-notes-label"
                  }`}
                >
                  <span>
                    {tenantInfo && (
                      <Editor
                        toolbarHidden
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName= {`${tenantInfo?.notes?.length > 400 && !showMore && "tenant-notes-label"}`}
                      />
                    )}
                  </span>
                </span>
                {tenantInfo?.notes?.length > 400 && (
                  <span role="button" onClick={() => setShowMore(!showMore)}>
                    <u className={`text-primary ${showMore && "pl-1"} pe-auto`}>
                      {showMore ? "Show less" : "read more"}
                    </u>
                  </span>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
}

export default TenantNotes;
