import axios, { AxiosInstance } from "axios";
import { Shift, Config } from "../types";

class ShiftsAPI {
  httpClient: AxiosInstance;

  constructor(config: Config) {
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async createShift(shift: Shift) {
    const response: any = await this.httpClient.post("shifts", shift);
    return response.data.shift;
  }

  async updateShift(id: string | number, shift: Shift) {
    const response: any = await this.httpClient.put(`shifts/${id}`, shift);
    return response.data.shift;
  }

  async deleteShift(shift: Shift) {
    try {
      await this.httpClient.delete(`shifts/${shift.id}`);
      return true;
    } catch (e) {
      return false;
    }
  }

  async loadShifts(start: Date, end: Date) {
    const response: any = await this.httpClient.get("shifts", {
      params: { startDate: start.getTime(), endDate: end.getTime() },
    });
    return response.data.shifts;
  }

  async copyShift(shift: any) {
    try {
      await this.httpClient.post("shifts/copy", shift);
      return true;
    } catch (e) {
      return false;
    }
  }

  async uploadShifts(data: any) {
    try {
      let response: any = await this.httpClient.post("shifts/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      return false;
    }
  }
}

export default ShiftsAPI;
