import axios, { AxiosInstance } from "axios";
import { Config } from "../types";

class FeatureToggleApi {
  httpClient: AxiosInstance;
  config: Config;

  constructor(config: Config) {
    this.config = config;
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async featureToggle(feature: string, tenantId: number | undefined) {
    const response: any = await this.httpClient.post(`/feature_toggles`, {
      feature,
      tenantId,
    });
    return response.data.result;
  }
}

export default FeatureToggleApi;
