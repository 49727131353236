import React, { useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";

const UploadShiftsModal = ({
  closeModal,
  api,
  shiftsUploaded,
  timeZone,
}: {
  closeModal: () => void;
  api: any;
  shiftsUploaded: () => void;
  timeZone: string;
}) => {
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const onFileSelected = (e: any) => {
    if (e !== undefined) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const uploadFile = async () => {
    if (selectedFile !== undefined) {
      const data = new FormData();
      //@ts-ignore
      data.append("shifts", selectedFile);

      try {
        setUploading(true);
        const result = await api.uploadShifts(data);
        toast.info(
          `${result.successCount} shifts were uploaded successfully.\n Failed shifts: ${result.failureCount}`,
          {
            autoClose: 10000,
          }
        );
        shiftsUploaded();
      } catch (e) {
        setUploading(false);
        toast.error("Failed to upload shifts. Please try again.");
      }
    }
  };

  return (
    <Modal show={true} onHide={closeModal} animation={false}>
      <Modal.Header closeButton>Upload technician shifts</Modal.Header>
      <Modal.Body>
        {" "}
        <p style={{ fontSize: "14px" }}>
          Please choose a file with technician shifts and click proceed. Note
          that all times should be in your configured timezone ({timeZone}).
          <br />
          <a href={"/csv-upload-template.csv"} download>
            (Download CSV Template)
          </a>
        </p>
        {uploading ? (
          <ProgressBar animated now={100} />
        ) : (
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={onFileSelected}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="r24btn"
          data-dismiss="modal"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={uploading}
          className="r24btn r24btn--pri r24btn--wide"
          onClick={uploadFile}
        >
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadShiftsModal;
