import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NotesForm from "./NotesForm";
import Modal from "react-bootstrap/Modal";
import { PencilSquare, XLg as Close, PlusSquare } from "react-bootstrap-icons";
import { Technician, Tenant } from "../../types";
import moment from "moment";

function NewNotesModal({
  data,
  saveNotesApi,
  cancel,
  onSaveNotes,
  notesFor,
}: {
  notesFor: string;
  cancel: () => void;
  saveNotesApi: any;
  data: any;
  onSaveNotes: (data: any) => void;
}) {
  const [updating, setUpdating] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [forLabel, setForLabel] = useState(notesFor);

  useEffect(() => {
    if (notesFor === "date") {
      setForLabel(moment(data?.date).format("LL"));
    } else if (notesFor === "technician") {
      setForLabel(data?.firstName);
    } else {
      setForLabel("");
    }
  }, [notesFor]);

  const saveNotes = async (params: Technician | Tenant) => {
    try {
      setUpdating(true);
      const result = await saveNotesApi({
        ...data,
        ...params,
      });
      onSaveNotes(result);
      toast.success("Note was successfully updated.");
    } catch (e) {
      console.log("technicianParams:error", e);
      setUpdating(false);
      toast.error("Error saving notes. Please try again.");
    }
  };

  return (
    <Modal show={true} onHide={cancel} size='lg'>
      <Modal.Header closeButton={false}>
        <label>
          {forLabel
            ? data?.notes
              ? `Update notes for ${forLabel}`
              : `Add notes for ${forLabel}`
            : "Notes"}
        </label>
        <div>
          {!showForm ? (
            data?.notes ? (
              <PencilSquare
                role="button"
                color="#19333F"
                size={15}
                onClick={() => {
                  setShowForm(true);
                }}
              />
            ) : (
              <PlusSquare
                role="button"
                color="#19333F"
                size={15}
                onClick={() => {
                  setShowForm(true);
                }}
              />
            )
          ) : (
            <></>
          )}
          <Close
            className="ml-3"
            role="button"
            color="#19333F"
            size={15}
            onClick={cancel}
          />
        </div>
      </Modal.Header>
      <NotesForm
        cancel={cancel}
        saveNote={saveNotes}
        notesTarget={data}
        submitting={updating}
        showForm={showForm}
        notesFor={notesFor}
      />
    </Modal>
  );
}

export default NewNotesModal;
