import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import { Config } from "../types"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ExportScheduleAPI from "../api/ExportScheduleAPI";
const moment = require('moment-timezone')
const ExportSchedule = ({ config }: { config: Config; }) => {

  const { handleSubmit, control } = useForm();
  const [showExportModal, setShowExportModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const exportScheduleAPI = new ExportScheduleAPI(config);

  const onSubmit = async (data: any) => {
    setSubmitting(true)
    const startDate = moment(data.dateRange?.selection?.startDate).startOf('day').toDate()
    const endDate = moment(data.dateRange?.selection?.endDate).endOf('day').toDate()
    await exportScheduleAPI.download(
      startDate,
      endDate
    )
    setSubmitting(false)
    setShowExportModal(false)
  }

  const initialDateRange = {
    startDate: moment.utc(new Date()).toDate(),
    endDate: moment.utc(new Date()).toDate(),
    key: 'selection',
  }
  return(
    <>
      <button onClick={() => {setShowExportModal(true)}} className="r24btn r24btn--small r24btn--light export-schedule-button">
        Export Schedule
      </button>

      <Modal
        dialogClassName="export-schedule-modal"
        show={showExportModal}
        onHide={() => {
          setShowExportModal(false)
          setSubmitting(false)
        }}
      >
        <Modal.Header closeButton>Export Schedule</Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <div className="export-schedule">
                <label className="r24fg__label">Select Date</label>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ onChange, value }) => (
                    <DateRangePicker
                      ranges={[value?.selection || initialDateRange]}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              
            </Modal.Body>
            <div className="modal-footer">
              <button
                type="submit"
                disabled={submitting}
                className="r24btn r24btn--small r24btn--pri export-schedule-action"
              >
                {submitting
                  ? "Exporting..."
                  : "Submit"
                }
              </button>

              <input
                type="button"
                className="r24btn r24btn--small r24btn--light export-schedule-action"
                onClick={() => {setShowExportModal(false)}}
                value="Cancel"
              >
              </input>
            </div>
          </form>
      </Modal>
    </>

  )
}

export default ExportSchedule