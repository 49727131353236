import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function DeleteTechnician(
{
  deleteTechnician,
  close,
}:{
  deleteTechnician: () => void;
  close: () => void;
}
) {
  const [submitting, setSubmitting] = useState(false)

  return(
    <>
      <Modal
        centered
        dialogClassName="delete-technician-modal"
        show={true}
        onHide={close}
      >
        <Modal.Header closeButton>Please Confirm</Modal.Header>
        <Modal.Body>
          Are you sure you want to delete?
        </Modal.Body>
        <div className="modal-footer">
          <input
            type="button"
            className="r24btn r24btn--small r24btn--light export-schedule-action"
            onClick={close}
            value="Cancel"
          >
          </input>

          <button
            type="submit"
            onClick={() => {
              setSubmitting(true)
              deleteTechnician()
            }}
            className="r24btn r24btn--small r24btn--pri export-schedule-action"
          >
            {submitting
              ? "Deleting..."
              : "Confirm"
            }
          </button>
        </div>
      </Modal>
    </>
  )
}
export default DeleteTechnician